
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class DevAlgorithms extends Vue {
        // Data function
        public data() {
            return {
                leadProgrammerIconPathAndName:
                    require("@/assets/images/icon_lead_programmer.png"),
            };
        }
    }
